import { RootNode } from '@strapi/blocks-react-renderer/dist/BlocksRenderer';
import classNames from 'classnames';

import StrapiRichTextComponent from '../StrapiRichText';
import styles from './SectionHeaderView.module.scss';

export const SectionHeaderView = ({
  title,
  desktopAlignment,
  mobileAlignment,
  underline,
}: {
  title: RootNode[];
  desktopAlignment: 'LEFT' | 'CENTER' | undefined;
  mobileAlignment: 'LEFT' | 'CENTER' | undefined;
  underline: 'NONE' | 'COLORED' | 'GREY' | undefined;
}) => (
  <div
    className={classNames('p--serif', {
      'd-flex fd-column ai-center': desktopAlignment === 'CENTER',
      [styles.mobileCenter]: mobileAlignment === 'CENTER',
    })}
  >
    <StrapiRichTextComponent richText={title} />
    <div
      data-aos="animate-underline"
      data-aos-once="true"
      className={classNames({
        [styles.separator]: underline === 'COLORED',
        [styles.separatorGrey]: underline === 'GREY',
      })}
    />
  </div>
);
