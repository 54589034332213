import { APIResponse } from 'strapi/types/utils';

export const getDimensions = (
  image: APIResponse<'plugin::upload.file'>
): { width: number; height: number } => {
  if (!image.data) throw Error('Missing image data');

  const { width, height } = image.data.attributes;

  if (width && height)
    return {
      width,
      height,
    };

  throw Error('Dimensions were missing on the image payload');
};
