import { Card, InfoCard } from '@popsure/dirty-swan';
import classNames from 'classnames';
import CtaLink from 'components/CtaLink';
import { IconTile } from 'content/household-insurance/components/WhatsCoveredSection/IconTile';
import Image from 'next/image';
import React from 'react';
import SectionHeaderView from 'strapi/components/SectionHeaderView';
import StrapiRichText from 'strapi/components/StrapiRichText';
import { BlockComponentProps } from 'strapi/types/utils';

import styles from './InfoCardSection.module.scss';

export const InfoCardSection = ({
  block,
}: BlockComponentProps<'marketing-website.info-card-section'>) => {
  const {
    has_background_color: hasBackgroundColor,
    title,
    desktop_alignment: desktopAlignment,
    mobile_alignment: mobileAlignment,
    underline,
    column_count: columnCount = 2,
    bottom_link: bottomLink,
    bottom_link_text: bottomLinkText,
    items,
    variation,
  } = block;

  return (
    <section
      className={classNames('mt56', styles.container, {
        [styles.background]: hasBackgroundColor,
        py72: hasBackgroundColor,
      })}
    >
      <div className="p-body d-flex fd-column jc-center">
        {title && (
          <SectionHeaderView
            title={title}
            desktopAlignment={desktopAlignment}
            mobileAlignment={mobileAlignment}
            underline={underline}
          />
        )}
        <div className={`mt32 ${styles[`column-${columnCount}`]}`}>
          {items.map((item) => {
            if (!item.card_icon?.data) throw Error('Missing card icon');
            const { url: src, alternativeText: alt } =
              item.card_icon.data.attributes;

            if (!alt)
              throw Error(
                `Missing alt text for the icon on "${item.card_title}"`
              );

            return (
              <div key={item.card_title} className="my16">
                {
                  {
                    default: (
                      <InfoCard
                        className={styles.cardContainer}
                        title={item.card_title}
                        topIcon={{
                          src,
                          alt,
                        }}
                        state="static"
                      >
                        <div className="d-block ta-center">
                          <StrapiRichText
                            richText={item.card_description_richtext}
                            paragraphClassName="p-p tc-grey-600"
                          />
                        </div>
                      </InfoCard>
                    ),
                    withTopLeftIcon: (
                      <Card
                        title={item.card_title}
                        titleVariant="medium"
                        dropShadow={false}
                        classNames={{ icon: styles.topLeftIcon }}
                        icon={
                          <Image src={src} alt={alt} width={28} height={28} />
                        }
                        description={
                          <StrapiRichText
                            richText={item.card_description_richtext}
                            paragraphClassName="p-p tc-grey-600"
                          />
                        }
                      />
                    ),
                    withLargeIcon: (
                      <div className="d-flex mx16">
                        <IconTile
                          title={item.card_title}
                          iconSrc={src}
                          iconAlt={alt}
                        >
                          <StrapiRichText
                            richText={item.card_description_richtext}
                            paragraphClassName={styles.withLargeIconParagraph}
                          />
                        </IconTile>
                      </div>
                    ),
                  }[variation]
                }
              </div>
            );
          })}
        </div>
        {bottomLink && bottomLinkText && (
          <CtaLink
            href={bottomLink.url}
            className="p-a p-p fw-bold c-pointer bg-transparent my24 mx-auto"
          >
            {bottomLinkText}
          </CtaLink>
        )}
      </div>
    </section>
  );
};
