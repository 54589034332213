import classNames from 'classnames';
import CtaLink from 'components/CtaLink';
import Image from 'next/image';
import React from 'react';
import { BlockComponentProps } from 'strapi/types/utils';
import { getDimensions } from 'strapi/utils/images';
import { getResponsiveImgHeight } from 'util/getResponsiveImgSize';

import StrapiRichText from '../StrapiRichText';
import styles from './TextWithImage.module.scss';

export const TextWithImage = ({
  block,
}: BlockComponentProps<'marketing-website.text-with-image'>) => {
  const {
    title,
    underline,
    image,
    image_size: imageSize,
    imagePosition,
    badge,
    body,
    button_link: buttonLink,
    button_text: buttonText,
  } = block;

  if (!image?.data) throw Error('Missing image data');
  if (!image.data.attributes.alternativeText)
    throw Error('Missing alt text for the image');

  /* We calculate the image height based on the max width we know the image will have on the page, and pass the dimensions to Image:
  https://nextjs.org/docs/pages/building-your-application/optimizing/images#image-sizing */
  const RESIZED_IMAGE_WIDTH = 400;
  const responsiveHeight = getResponsiveImgHeight(
    RESIZED_IMAGE_WIDTH,
    getDimensions(image)
  );

  return (
    <section
      className={classNames(
        'p-body d-flex ai-center gap32 mt56 text-img-container',
        styles.container,
        {
          [styles.smallImage]: imageSize === 'SMALL',
          [styles.bigImage]: imageSize === 'BIG',
        }
      )}
    >
      <div
        className={classNames(styles.imageContainer, {
          [styles.right]: imagePosition === 'RIGHT',
          [styles.left]: imagePosition !== 'RIGHT',
        })}
      >
        <Image
          src={image.data.attributes.url}
          width={RESIZED_IMAGE_WIDTH}
          height={responsiveHeight}
          alt={image.data.attributes.alternativeText}
        />
      </div>
      <div
        className={classNames(styles.textContainer, {
          [styles.right]: imagePosition !== 'RIGHT',
          [styles.left]: imagePosition === 'RIGHT',
        })}
      >
        {badge && (
          <div
            className={`fw-bold p-badge p-badge--default mb8 ${styles.badge}`}
          >
            {badge}
          </div>
        )}
        <h2
          className={classNames('p--serif', {
            'p-h2': underline !== 'NONE',
            'p-h3': underline === 'NONE',
          })}
        >
          {title}
        </h2>
        {underline && underline !== 'NONE' && (
          <div
            data-aos="animate-underline"
            data-aos-once="true"
            className={classNames({
              [styles.separator]: underline === 'COLORED',
              [styles.separatorGrey]: underline === 'GREY',
            })}
          />
        )}
        {body && (
          <div className={`mt16 ${styles.paragraphWrapper}`}>
            <StrapiRichText
              richText={body}
              paragraphClassName="p-p mt8 tc-grey-600"
              listClassName="tc-grey-600"
            />
          </div>
        )}
        {buttonLink && buttonText && (
          <CtaLink
            href={buttonLink.url}
            className={classNames('p-btn--secondary-grey mt24', styles.ctaLink)}
          >
            {buttonText}
          </CtaLink>
        )}
      </div>
    </section>
  );
};
