import MissingRequiredFields from 'components/MissingRequiredFields';
import React from 'react';
import { BlockComponentProps } from 'strapi/types/utils';

import SectionHeaderView from '../SectionHeaderView';
import styles from './SectionHeader.module.scss';

export const SectionHeader = ({
  block,
}: BlockComponentProps<'marketing-website.section-header'>) => {
  const {
    title,
    mobile_alignment: mobileAlignment,
    desktop_alignment: desktopAlignment,
    underline,
  } = block;
  if (!title.length) return <MissingRequiredFields />;
  return (
    <section className={`p-body header-container ${styles.container}`}>
      <SectionHeaderView
        title={title}
        mobileAlignment={mobileAlignment}
        desktopAlignment={desktopAlignment}
        underline={underline}
      />
    </section>
  );
};
