import classNames from 'classnames';
import LinkTo from 'components/linkTo';
import Image from 'next/image';
import React from 'react';
import SectionHeaderView from 'strapi/components/SectionHeaderView';
import { BlockComponentProps } from 'strapi/types/utils';

import styles from './ButtonCardSection.module.scss';

export const ButtonCardSection = ({
  block,
}: BlockComponentProps<'marketing-website.button-card-section'>) => {
  const { header, items } = block;

  return (
    <section className={classNames('mt56', styles.container)}>
      <div className="p-body d-flex fd-column jc-center">
        {header && (
          <SectionHeaderView
            title={header.title}
            desktopAlignment={header.desktop_alignment}
            mobileAlignment={header.mobile_alignment}
            underline="COLORED"
          />
        )}
        <div className={`mt32 ${styles.column}`}>
          {items.map((item) => {
            if (!item.cardIcon?.data) throw Error('Missing card icon');
            const { url: src, alternativeText: alt } =
              item.cardIcon.data.attributes;

            if (!alt)
              throw Error(
                `Missing alt text for the icon on "${item.cardTitle}"`
              );

            return (
              <LinkTo key={item.cardTitle} href={item.cardLink ?? ''}>
                <div
                  className={classNames('d-flex ai-center', styles.cardWrapper)}
                >
                  <div
                    className={classNames(
                      'br8 d-flex ai-center jc-center mr16',
                      styles.imgWrapper
                    )}
                  >
                    <Image
                      src={src}
                      alt={alt}
                      className={classNames(styles.img)}
                      width={80}
                      height={80}
                    />
                  </div>
                  <div className={styles.content}>
                    <h3 className="p-h4">{item.cardTitle} </h3>
                    <p className="p-p--small tc-grey-600">
                      {item.cardDescription}
                    </p>
                  </div>
                </div>
              </LinkTo>
            );
          })}
        </div>
      </div>
    </section>
  );
};
