import { website } from '@getpopsure/private-constants';
import classNames from 'classnames';
import BottomCTA, { CtaBackgroundPattern } from 'components/bottomCTA';
import { EventJsonLd } from 'next-seo';
import React from 'react';
import { BlockComponentProps } from 'strapi/types/utils';
import { convertToBerlinTime } from 'strapi/utils/convertToBerlinTime';

import styles from './CtaBanner.module.scss';

export const CtaBanner = ({
  block,
}: BlockComponentProps<'marketing-website.cta-banner'>) => {
  const {
    title,
    description,
    eventDetails,
    primary_cta_text: primaryCtaText,
    primary_cta_link: primaryCtaLink,
    secondary_cta_text: secondaryCtaText,
    secondary_cta_link: secondaryCtaLink,
    background_pattern: backgroundPatternOption,
    is_full_width: isFullWidth,
    cta_link_id: ctaLinkId,
  } = block;

  let backgroundPattern: CtaBackgroundPattern;
  switch (backgroundPatternOption) {
    case 'GREEN_CENTERED':
      backgroundPattern = 'green-centered';
      break;
    case 'GREEN_SIDE':
      backgroundPattern = 'green-side';
      break;
    case 'GREEN_SMALL':
      backgroundPattern = 'green-small';
      break;
    case 'RED_CENTERED':
      backgroundPattern = 'red-centered';
      break;
    case 'RED_SIDE':
      backgroundPattern = 'red-side';
      break;
    case 'RED_SMALL':
      backgroundPattern = 'red-small';
      break;
    default:
      backgroundPattern = 'green-centered';
  }

  if (!primaryCtaLink) {
    throw Error('Missing primary_cta_link attribute');
  }

  return (
    <section
      className={classNames('mt56', styles.container, {
        'p-container': !isFullWidth,
        [styles.smallCTAContainer]: !isFullWidth,
      })}
    >
      {eventDetails && (
        <EventJsonLd
          name={eventDetails.name}
          url={eventDetails.url}
          startDate={convertToBerlinTime(eventDetails.startDate)}
          endDate={convertToBerlinTime(eventDetails.endDate)}
          location={{ url: eventDetails.url }}
          description={eventDetails.description}
          organizer={{
            type: 'Organization',
            name: 'Feather Insurance',
            url: website.base,
          }}
          eventAttendanceMode="OnlineEventAttendanceMode"
        />
      )}
      <BottomCTA
        title={title}
        description={description}
        action={primaryCtaText}
        ctaLinkId={ctaLinkId}
        handleClickOrRedirectUrl={primaryCtaLink.url}
        secondaryAction={secondaryCtaText}
        handleSecondaryClickOrRedirectUrl={secondaryCtaLink?.url}
        backgroundPattern={backgroundPattern}
        linkTarget={primaryCtaLink.target}
        secondaryLinkTarget={secondaryCtaLink?.target}
      />
    </section>
  );
};
